import React, { useState } from "react";
import CommonBanner from "../../component/Common/Banner";
import ListadoRegulaciones from "../../component/MejoraRegulatoria/Regulaciones/ListadorRegulaciones";
import BuscadorRegulaciones from "../../component/MejoraRegulatoria/Regulaciones/BuscadorRegulaciones";

const RegistrodeRegulaciones = () => {
  const [categoriaSeleccionada, setCategoriaSeleccionada] = useState("");

  return (
    <>
      <CommonBanner
        heading="Registro Municipal de Regulaciones"
        page="Registro Municipal de Regulaciones"
      />
      <section id="service_details_area">
        <div className="container">
          <div className="row">
            <BuscadorRegulaciones
              onCategoriaChange={setCategoriaSeleccionada}
            />
            <ListadoRegulaciones
              categoriaSeleccionada={categoriaSeleccionada}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default RegistrodeRegulaciones;
