import React from "react";
import { SareData } from "./SareData";
import SectionHeading from "../../Common/SectionHeading";
import ServiceCard from "../../Common/Service/ServiceCard";
import "./SareInfo.css";

const SareInfo = () => {
  return (
    <section id="services_page">
      <div className="container">
        <SectionHeading
          heading="Sistema de Apertura Rápida de Empresas (SARE)"
          para="Obtén tu Licencia de Uso de Suelo y de Funcionamiento Municipal en 3 días"
        />
        <div className="service_wrapper_top">
          {/* Agregamos row con gutters (espaciado entre columnas) */}
          <div className="row g-4">
            
            {SareData.map((data, index) => (
              <div className="col-lg-6 col-md-6 col-sm-12" key={index}>
                 <p></p>
                <div className="uniform-card">
                  <ServiceCard
                    img={data.img}
                    heading={data.heading}
                    para={data.para}
                    button={data.button}
                    url={data.url}
                  />
               
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default SareInfo;
