import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Badge, Container } from "react-bootstrap";
import { AiOutlineFilePdf } from "react-icons/ai";
import "./TablaRegulaciones.css";

const ListadoRegulaciones = ({ categoriaSeleccionada }) => {
  const [categorias, setCategorias] = useState([]);
  const [regulaciones, setRegulaciones] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategoriasYRegulaciones = async () => {
      try {
        // Obtener categorías
        const categoriasResponse = await axios.get(
          `${process.env.REACT_APP_URL_GRAPH_QL}/regulaciones/categoria-regulacion`
        );

        if (categoriasResponse.data.success) {
          setCategorias(categoriasResponse.data.data);
        }

        // Obtener regulaciones
        const regulacionesResponse = await axios.get(
          `${process.env.REACT_APP_URL_GRAPH_QL}/regulaciones`
        );

        if (regulacionesResponse.data.success) {
          setRegulaciones(regulacionesResponse.data.data);
        }
      } catch (error) {
        console.error("Error al obtener datos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategoriasYRegulaciones();
  }, []);

  if (loading) {
    return <div className="text-center my-5">Cargando regulaciones...</div>;
  }

  // Filtrar por la categoría seleccionada
  const categoriasFiltradas = categoriaSeleccionada
    ? categorias.filter((cat) => cat.id === categoriaSeleccionada)
    : categorias;

  return (
    <Container>
      {categoriasFiltradas.map((categoria) => {
        const regulacionesCategoria = regulaciones.filter(
          (reg) => reg.categoria_id === categoria.id
        );

        return (
          <div key={categoria.id} className="mb-4">
            <h4 className="text-primary">{categoria.categoria}</h4>
            <Table striped bordered hover responsive>
              <thead className="table-dark">
                <tr>
                  <th style={{ width: "50%" }}>Regulación</th>
                  <th style={{ width: "20%" }}>Última Reforma</th>
                  <th style={{ width: "15%" }}>Ficha Técnica</th>
                  <th style={{ width: "15%" }}>Descargables</th>
                </tr>
              </thead>
              <tbody>
                {regulacionesCategoria.length > 0 ? (
                  regulacionesCategoria.map((reg) => (
                    <tr key={reg.id}>
                      <td>
                        <strong>{reg.regulacion || "Sin nombre"}</strong>
                        <br />
                        <small className="text-muted">
                          {reg.fecha_original
                            ? `Original: ${new Date(
                                reg.fecha_original
                              ).toLocaleDateString()}`
                            : ""}
                        </small>
                      </td>
                      <td>
                        {reg.ultima_reforma
                          ? `G.M. ${new Date(
                              reg.ultima_reforma
                            ).toLocaleDateString()}`
                          : "N/A"}
                      </td>
                      <td className="text-center">
                        {reg.ficha_tecnica ? (
                          <Badge bg="success">📄</Badge>
                        ) : (
                          <Badge bg="secondary">N/A</Badge>
                        )}
                      </td>
                      <td className="text-center">
                        {reg.descargables ? (
                          <a
                            href={`${process.env.REACT_APP_AZURE_DOCS}${reg.descargables}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="icon-link"
                          >
                            <AiOutlineFilePdf size={22} color="#dc3545" />
                          </a>
                        ) : (
                          <Badge bg="secondary">N/A</Badge>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="text-center text-muted">
                      No hay regulaciones en esta categoría
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        );
      })}
    </Container>
  );
};

export default ListadoRegulaciones;
