import catalogoTramites from "../../../assets/img/lzc/catramites.jpg";
import solicitudTramites from "../../../assets/img/lzc/solicitud.jpg";
import cabildoacta from "../../../assets/img/lzc/cabildoacta.jpg";
import manualProcedimientos from "../../../assets/img/lzc/manualprecedimientos.jpg";
export const SareData = [
  {
    img: catalogoTramites,
    heading: "Catálogo de Giros",
    para: "Consulta el Catálogo de Giros Blancos Bajo Impacto.",
    button: "Descargar",
    url: "https://lazarocardenas.blob.core.windows.net/webportal/Catálogo de Giros blancos bajo riesgo.pdf",
  },

  {
    img: cabildoacta,
    heading: "Acta de Cabildo",
    para: " Fundamento legal Acta de Cabildo.",
    button: "Descargar",
    url: "https://lazarocardenas.blob.core.windows.net/webportal/20221027135707914.pdf",
  },
  {
    img: manualProcedimientos,
    heading: "Manual de Procedimientos",
    para: "Manual de Procedimientos SARE.",
    button: "Descargar",
    url: "https://lazarocardenas.blob.core.windows.net/webportal/MANUAL DE PROCEDIMIENTOS SARE Mod..pdf",
  },
  {
    img: solicitudTramites,
    heading: "Solicitud",
    para: " Consulta y Descarga el Formato de Solicitud de Licencia de Funcionamiento.",
    button: "Descargar",
    url: "https://lazarocardenas.blob.core.windows.net/webportal/Solicitud de Licencia (Formato único).pdf",
  },
];
