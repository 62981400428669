import CommonBanner from "../../component/Common/Banner";
import SareInfo from "../../component/MejoraRegulatoria/Sare/SareInfo";


const SARE =()=>{
    return (
        <>
         <CommonBanner heading="SARE" page="SARE"/>
         <SareInfo/>
        </>
    )
}

export default SARE;