import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, Form } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import "./BuscadorRegulaciones.css"; // Archivo de estilos

const BuscadorRegulaciones = ({ onCategoriaChange }) => {
  const [categorias, setCategorias] = useState([]);

  useEffect(() => {
    const fetchCategorias = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_URL_GRAPH_QL}/regulaciones/categoria-regulacion`
        );

        if (response.data.success) {
          setCategorias(response.data.data);
        }
      } catch (error) {
        console.error("Error al obtener categorías:", error);
      }
    };

    fetchCategorias();
  }, []);

  return (
    <div className="col-lg-12">
      <Card className="shadow-sm mb-4">
        <Card.Body>
          <h4 className="text-danger mb-3">
            <AiOutlineSearch className="me-2" />{" "}
            <strong>Buscar Regulaciones</strong>
          </h4>

          {/* Select para filtrar por categoría */}
          <Form.Group controlId="categoriaSelect">
            <Form.Label>Filtrar por Categoría:</Form.Label>
            <Form.Select onChange={(e) => onCategoriaChange(e.target.value)}>
              <option value="">Todas las categorías</option>
              {categorias.map((cat) => (
                <option key={cat.id} value={cat.id}>
                  {cat.categoria}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Card.Body>
      </Card>
    </div>
  );
};

export default BuscadorRegulaciones;
