import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
} from "react-router-dom";
// Import Page Layout
import Navbar from "./component/Common/Navbar";
import Footer from "./component/Common/Footer";
import CopyRight from "./component/Common/CopyRight";
// Import All Components
import Home_One from "./page/index";
import Contraloria from "./page/Contraloria";

// Site
import ProtestaCiudadana from "./page/Mejora/ProtestaCiudadana";

// Import ScrollToTop Components
import ScrollToTop from "./component/ScrollToTop";
import Licitaciones from "./page/Compras/Licitaciones";
import { ApolloProvider } from "@apollo/client";
import client from "./gql/apolloClient";
import RegistrodeRegulaciones from "./page/Mejora/RegistrodeRegulaciones";
import SARE from "./page/Mejora/SARE";

const App = () => {
  return (
    <>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Router>
            <ScrollToTop>
              <Navbar />
              <Switch>
                <Route path="/" exact component={Home_One} />
                <Route path="/contraloria" exact component={Contraloria} />
                <Route
                  path="/mejora/protesta-ciudadana"
                  exact
                  component={ProtestaCiudadana}
                />
                <Route
                  path="/mejora/remure"
                  exact
                  component={RegistrodeRegulaciones}
                />
                <Route 
                  path="/mejora/sare"
                  exact 
                  component={SARE}
                />
                <Route
                  path="/compras/licitaciones"
                  exact
                  component={Licitaciones}
                />
                <Route
                  path="/cartelera/"
                  component={() => {
                    window.location.href =
                      "https://drive.google.com/drive/folders/1vneIEwvuUZO_93Owm4rt3O93azK-LlTR";
                    return null;
                  }}
                />
                <Route exact component={Error} />
              </Switch>
              <Footer />
              <CopyRight />
            </ScrollToTop>
          </Router>
        </BrowserRouter>
      </ApolloProvider>
    </>
  );
};

export default App;
